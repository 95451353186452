<template>
    <div>
        <el-dialog title="查看" :before-close="handleClose" :visible.sync="dialog" direction="rtl"
            custom-class="demo-drawer" ref="drawer" size="60%">
          <div class="side" v-html="message"></div>
        </el-dialog>
    </div>
</template>
<script>

export default {
    name: "notificaShow",
    data() {
        return {
            message: null,
            dialog:false
        };
    },
    methods: {
        show(message) {
            console.log('messgs',message)
            this.dialog = true;
            this.message=message.result
        },
        handleClose(done) {
            if (this.loading) {
                return;
            }
            this.$confirm("关闭")
                .then(_ => {
                    this.cancelForm();
                })
                .catch(_ => { });
        },
        cancelForm() {
            this.dialog = false;
            this.message = null
        },

    }
};
</script>
<style lang="less" scoped>

.side{
    background-color: lightblue;
}
</style>


