<template>
    <div>
        <el-dialog :title="title" :before-close="handleClose" :visible.sync="dialog" direction="rtl"
            custom-class="demo-drawer" ref="drawer" size="60%">
            <el-button @click="notificaAddButton">新增</el-button>
            <el-table :data="dataColumnList">
                <el-table-column label="序号" type="index" align="center" width="65" />
                <el-table-column label="名称" align="center" prop="name" />
                <el-table-column label="标题" align="center" prop="contentTitle" />
                <el-table-column label="接口名称" align="center" prop="resourceName" />
                <el-table-column label="类型" align="center" prop="type" />
                <el-table-column label="最近发送时间" align="center" prop="lastSendAt" />
                <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
                    <template slot-scope="scope">
                        <el-button size="mini" type="text" icon="el-icon-delete"
                            @click="notificaSelectButton(scope.row)">查看</el-button>
                        <el-button size="mini" type="text" icon="el-icon-delete"
                            @click="getNotificalButton(scope.row)">获取发送消息</el-button>
                        <el-button size="mini" type="text" icon="el-icon-delete"
                            @click="sendNotificalButton(scope.row)">发送</el-button>
                        <!-- <el-button size="mini" type="text" icon="el-icon-delete"
                            @click="notificaEditButton(scope.row)">编辑</el-button> -->
                            <el-button size="mini" type="text" icon="el-icon-delete"
                            @click="deleteByIdInfomaButton(scope.row)">删除</el-button>
                            
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
        <notificaAdd ref="notificaAddFrom" />
        <showNotifiva ref="showNotifivaFrom" />
    </div>
</template>
<script>


import {
    infomaListApi,
    getInterfaceListApi,
    getSingleDataApi,
    sendSingleDataApi,
    deleteByIdInfomaApi
} from "@/api/work/bots/bots.js";

import notificaAdd from './notificaAdd.vue';
import showNotifiva from './showNotifiva.vue';
export default {
    name: "notificaShow",
    components: { notificaAdd, showNotifiva },
    data() {
        return {
            dataColumnList: [],
            dialog: false,
            loading: false,
            title: null,
            addEditForm: {},
            botsId: null
        };
    },
    methods: {
        show(row, type) {
            this.botsId = row.uniqueId
            this.dialog = true;
            this.selectListButton(row)
            this.getInterfaceListButton();
        },
        handleClose(done) {
            if (this.loading) {
                return;
            }
            this.$confirm("关闭")
                .then(_ => {
                    this.cancelForm();
                    this.dialog = false;
                })
                .catch(_ => { });
        },
        cancelForm() {
            this.loading = false;
            this.dialog = false;
            this.editShow = false;
            this.botsId = null
            this.$refs['addEditForm'].resetFields();
            this.addEditForm = {}
            this.$parent.selectDataListButton();
        },
        sendNotificalButton(row) {
            sendSingleDataApi(row).then(res => {
                this.$message.info("发送成功");
            })
        },
        getNotificalButton(row) {
            getSingleDataApi(row).then(res => {
                console.log(res)
                this.$refs.showNotifivaFrom.show(res)
            })
        },
        getInterfaceListButton() {
            getInterfaceListApi().then(res => {
                this.getInterfaceList = res.result
            })
        },
        //查询详情
        selectListButton(row) {
            row.uniqueId = row.uniqueId === null ? this.botsId : row.uniqueId
            infomaListApi({ refId: row.uniqueId }).then(res => {
                this.dataColumnList = res.result.records
            })
        },
        addDialogClosed() {
            // 对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
            this.$refs['addEditForm'].resetFields();
        },
        AddSelectDept(row) {
            console.log(row)
        },
        notificaAddButton() {
            var row = {
                botsId: this.botsId
            }
            this.$refs.notificaAddFrom.show(row, 1);
        },
        notificaSelectButton(row) {
            row.botsId = this.botsId
            this.$refs.notificaAddFrom.show(row, 3);
        },
        notificaEditButton(row) {
            row.botsId = this.botsId
            this.$refs.notificaAddFrom.show(row, 2);
        },
        deleteByIdInfomaButton(row) {
            deleteByIdInfomaApi(row.id).then(res => {
                var data={
                    uniqueId:null
                }
                this.selectListButton(data)
            })
        }
    }
};
</script>
<style scoped></style>



