import { render, staticRenderFns } from "./notificaShow.vue?vue&type=template&id=34a70e4c&scoped=true&"
import script from "./notificaShow.vue?vue&type=script&lang=js&"
export * from "./notificaShow.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34a70e4c",
  null
  
)

export default component.exports